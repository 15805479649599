import React, { Component } from 'react'

import './ProcessColumns.scss'
import { ProcessBox, ProcessBoxRow } from '../'

export default class ProcessColumns extends Component {
  render() {
    return (
      <div className="process-columns">
        <div className="width">
          <div className="process-columns-columns">
            <div className="process-column">
              <ProcessBox>
                <ProcessBoxRow step="1">
                  <h3>Introduction call to understand name(s), budget, and goals</h3>
                </ProcessBoxRow>
                <ProcessBoxRow>Typically with CEO or project lead</ProcessBoxRow>
                <ProcessBoxRow>Craft a project plan that fits your budget and timeline</ProcessBoxRow>
              </ProcessBox>

              <ProcessBox>
                <ProcessBoxRow step="2">
                  <h3>Next Steps</h3>
                </ProcessBoxRow>
                <ProcessBoxRow>Contract review</ProcessBoxRow>
                <ProcessBoxRow>If applicable: naming exercise, discussing alternative options, continuing budget conversation</ProcessBoxRow>
                <ProcessBoxRow>Establishing a weekly or biweekly communication cadence</ProcessBoxRow>
              </ProcessBox>

              <ProcessBox>
                <ProcessBoxRow step="3">
                  <h3>Kickoff, Outreach, Escrow, Transfer</h3>
                </ProcessBoxRow>
                <ProcessBoxRow>Research-heavy outreach process</ProcessBoxRow>
                <ProcessBoxRow>Feeler offer and negotiations</ProcessBoxRow>
                <ProcessBoxRow>Escrow process via Escrow.com, licensed Escrow agent</ProcessBoxRow>
                <ProcessBoxRow>Work with client's internal dev ops / eng team or project lead on the domain transfer process to registrar of choice</ProcessBoxRow>
              </ProcessBox>
            </div>


    {/* <div className="process-column">
              <h2>Post-Acquisition</h2>

              <ProcessBox>
                <ProcessBoxRow step="1">
                  <h3>Introduction Call to understand name(s) + goals</h3>
                </ProcessBoxRow>
                <ProcessBoxRow icon="chat">Typically with CEO or project lead</ProcessBoxRow>
                <ProcessBoxRow icon="money">Create a project plan + pricing details</ProcessBoxRow>
              </ProcessBox>

              <ProcessBox>
                <ProcessBoxRow step="2">
                  <h3>Some other part of the process</h3>
                </ProcessBoxRow>
                <ProcessBoxRow icon="paper">Contract review</ProcessBoxRow>
                <ProcessBoxRow>Naming exercise + alternatives + budgeting</ProcessBoxRow>
                <ProcessBoxRow>Weekly communications plan review</ProcessBoxRow>
              </ProcessBox>

              <ProcessBox>
                <ProcessBoxRow step="2">
                  <h3>Name transfer process</h3>
                </ProcessBoxRow>
                <ProcessBoxRow icon="paper">
                  Handling final negotiations and escrow process
                </ProcessBoxRow>
                <ProcessBoxRow>Domain name transfer process</ProcessBoxRow>
                <ProcessBoxRow>
                  Working with internal dev ops/eng team lead to ensure complete transfer + securing
                  the name within your organization
                </ProcessBoxRow>
              </ProcessBox>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}
