import React, { Component } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import cookie from 'react-cookies'

import './App.scss'
import { Home, Sale, Process } from './pages'
import { Unlock } from './components'

export default class App extends Component {
  state = {
    unlocked: cookie.load('unlocked'),
    passwords: ['ExclusiveFriends', 'exclusivetw'],
    entered: '',
    version: cookie.load('version') || 'full'
  }

  handleChange = e => {
    const { value } = e.target
    this.setState({ entered: value })

    if (this.state.passwords.includes(value)) {
      cookie.save('unlocked', true, { path: '/', maxAge: 60 * 60 * 24 * 7 })
      this.setState({unlocked: true})

      if (value === this.state.passwords[1]) {
        this.setState({version: 'tw'})
        cookie.save('version', 'tw', { path: '/', maxAge: 60 * 60 * 24 * 7 })
      } else {
        cookie.save('version', 'full', { path: '/', maxAge: 60 * 60 * 24 * 7 })
      }
    }
  }

  render() {
    const { unlocked, entered } = this.state

    if (!unlocked) {
      return <Unlock onChange={this.handleChange} entered={entered} />
    }

    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/for-sale" exact component={Sale} />
          <Route path="/the-process" exact component={Process} />
        </Switch>
      </Router>
    )
  }
}
