import React from 'react'

export default function UpRight() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16">
      <path
        d="M15 12.5a1 1 0 0 1-1-1V3.422l-12.3 12.3A.995.995 0 1 1 .3 14.31L12.608 2H4.5a1 1 0 1 1 0-2H15a1 1 0 0 1 1 1v10.5a1 1 0 0 1-1 1z"
        fill="#FFF"
      />
    </svg>
  )
}
