import React, { Component } from 'react'

import './ProcessTransparent.scss'
import Calendly from '../Calendly/Calendly'
import { UpRight } from '../../svg'

export default class ProcessTransparent extends Component {
  render() {
    return (
      <div className="process-transparent">
        <div className="width">
          <div className="process-transparent-header ">
            <h2>Transparent Pricing</h2>
            <p>
              Throughout the duration of the engagement you will have phone/email/video access to
              the team.
            </p>
          </div>

          <div className="process-transparent-card">
            <h3>Each project</h3>
            <h4>$5,000 retainer*</h4>
            <p>10% of the purchase price ($1,000 minimum)</p>
            <p>
              <strong>OR</strong>
            </p>
            <p>20% of the difference between your budget and the sales price (aligned incentives)</p>
            <p>*Retainer is deducted from the success fee.</p>

            <Calendly>
              Schedule a meeting <UpRight />
            </Calendly>
          </div>
        </div>
      </div>
    )
  }
}
