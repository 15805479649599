import React from 'react'

export default function Sellside() {
  return (
    <svg width="40" height="30" viewBox="0 0 40 30">
      <path
        d="M36.667 30H3.333A3.333 3.333 0 0 1 0 26.667V3.333A3.333 3.333 0 0 1 3.333 0h33.334A3.333 3.333 0 0 1 40 3.333v23.334A3.333 3.333 0 0 1 36.667 30zM5 22.5c0 .46.373.833.833.833h3.334c.46 0 .833-.373.833-.833v-1.667A.833.833 0 0 0 9.167 20H5.833a.833.833 0 0 0-.833.833V22.5zm6.667-11.667a.833.833 0 0 0-.834-.833H4.167a.833.833 0 0 0-.834.833v5c0 .46.373.834.834.834h6.666c.46 0 .834-.373.834-.834v-5zm6.666 10A.833.833 0 0 0 17.5 20h-3.333a.833.833 0 0 0-.834.833V22.5c0 .46.373.833.834.833H17.5c.46 0 .833-.373.833-.833v-1.667zm8.334 0a.833.833 0 0 0-.834-.833H22.5a.833.833 0 0 0-.833.833V22.5c0 .46.373.833.833.833h3.333c.46 0 .834-.373.834-.833v-1.667zm8.333 0a.833.833 0 0 0-.833-.833h-3.334a.833.833 0 0 0-.833.833V22.5c0 .46.373.833.833.833h3.334c.46 0 .833-.373.833-.833v-1.667z"
        fill="#B446DA"
      />
    </svg>
  )
}
