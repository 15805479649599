import React, { Component } from 'react'

import './ProcessTop.scss'

export default class ProcessTop extends Component {
  render() {
    return (
      <div className="process-top">
        <div className="width">
          <h1>The Process</h1>
          <p>Working with us to acquire the perfect name is simple.</p>
        </div>
      </div>
    )
  }
}
