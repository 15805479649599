import React, { Component } from 'react'

import { Twitter as TwitterIcon } from '../../svg'
import './SaleFeatured.scss'

export default class SaleFeatured extends Component {
  getLogo = (Domain) => {
    return `/images/icons-featured/${Domain.toLowerCase().split('.')[0]}.svg`
  }

  render() {
    const { Domain, Price, Twitter } = this.props.domain
    
    return (
      <a className="sale-featured" href={'mailto:tw@exclusive.io?Subject=' + encodeURI(Domain)} target="_blank" rel="noopener noreferrer">
        <img src={this.getLogo(Domain)} alt={'Logo of ' + Domain} />
        <span>
          <h3>
            {Domain}
            {Twitter === 'Yes' && <TwitterIcon />}
          </h3>
          <h4>{Price}</h4>
        </span>
      </a>
    )
  }
}
