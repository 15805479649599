import React, { Component } from 'react'

import './HomeQuote.scss'

export default class HomeQuote extends Component {
  render() {
    const { quote, name, title } = this.props

    return (
      <figure className="home-quote">
        <span className="home-quote-inner">
          <blockquote>
            <img src="/images/quote-left.svg" alt="Quote" /> {quote}
            <img src="/images/quote-right.svg" alt="Quote" />
          </blockquote>

          <figcaption>
            {/* <span className="image">
              <img src={image} />
            </span> */}
            <span className="text">
              <h3>{name}</h3>
              <p>
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </p>
            </span>
          </figcaption>
        </span>
      </figure>
    )
  }
}
