import React from 'react'

export default function Paper() {
  return (
    <svg className="paper-svg" width="14" height="18" viewBox="0 0 14 18">
      <path
        d="M8.625 5.25a.375.375 0 0 1-.375-.375V.905A.375.375 0 0 1 8.89.64l3.97 3.97a.375.375 0 0 1-.265.64h-3.97zM13.5 7.125v9.75c0 .621-.504 1.125-1.125 1.125H1.125A1.125 1.125 0 0 1 0 16.875V1.125C0 .504.504 0 1.125 0h5.25C6.996 0 7.5.504 7.5 1.125v3.75C7.5 5.496 8.004 6 8.625 6h3.75c.621 0 1.125.504 1.125 1.125z"
        fill="#b9c6d0"
      />
    </svg>
  )
}
