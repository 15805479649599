import React, { Component } from 'react'

import './SaleTop.scss'

export default class SaleTop extends Component {
  render() {
    return (
      <div className="sale-top">
        <div className="width">
          <h1>Domains for Sale</h1>
          <p>
            We curate a handpicked list of brandable domains from our house portfolio and investor partners.
            Prices may be negotiable.
          </p>
        </div>
      </div>
    )
  }
}
