import React, { Component } from 'react'

import './SaleDomain.scss'
import { Right } from '../../svg'

export default class SaleDomain extends Component {
  render() {
    const { Domain, Price } = this.props.domain

    return (
      <a className="sale-domain" href={'mailto:tw@exclusive.io?Subject=' + encodeURI(Domain)} target="_blank" rel="noopener noreferrer">
        <span className="sale-domain-domain">{Domain}</span>
        <span className="sale-domain-description"></span>
        <span className="sale-domain-price">
          <span className="price">{Price}</span>
          <span className="interested">
            I'm interested <Right color="#0c7dd2" />
          </span>
        </span>
      </a>
    )
  }
}
