import React, { Component } from 'react'

export default class Calendly extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    const link = document.createElement('link');
    link.setAttribute('href', 'https://assets.calendly.com/assets/external/widget.css');
    link.setAttribute('rel', 'stylesheet');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    this.instance.appendChild(link);
    this.instance.appendChild(script);
  }

  openCalendly = (e) => {
    e.preventDefault();
    window.Calendly.initPopupWidget({url: 'https://calendly.com/exclusiveio/15min?text_color=2c6188&primary_color=0c7dd2'});
  }

  render() {
    return (
      <a className="button" href="/" ref={el => (this.instance = el)} onClick={this.openCalendly}>
        {this.props.children}
      </a>
    )
  }
}
