import React from 'react'

export default function Stealth() {
  return (
    <svg width="48" height="25" viewBox="0 0 48 25">
      <path
        d="M47.111 24.889H40a.889.889 0 0 1-.889-.889v-6.222l-1.127 1.127c-.417.416-.982.65-1.571.65h-3.817A.596.596 0 0 1 32 18.96v-.662c0-.306.208-.572.505-.646l3.05-.763 3.647-5.106c.5-.7 1.31-1.116 2.17-1.116h3.072A3.556 3.556 0 0 1 48 14.222V24c0 .49-.398.889-.889.889zm-3.555-16a4.444 4.444 0 1 1 0-8.889 4.444 4.444 0 0 1 0 8.889zM29.333 19.556H18.667a.889.889 0 0 1-.89-.89v-4.444a3.556 3.556 0 0 1 3.556-3.555h5.334a3.556 3.556 0 0 1 3.555 3.555v4.445c0 .49-.398.889-.889.889zM24 8.889A4.444 4.444 0 1 1 24 0a4.444 4.444 0 0 1 0 8.889zm-8.596 10.667h-3.817c-.59 0-1.154-.235-1.571-.651l-1.127-1.127V24c0 .49-.398.889-.889.889H.889A.889.889 0 0 1 0 24v-9.778a3.556 3.556 0 0 1 3.556-3.555h3.072c.86 0 1.669.416 2.17 1.116l3.646 5.106 3.051.763c.297.074.505.34.505.646v.662a.596.596 0 0 1-.596.596zM4.444 8.889a4.444 4.444 0 1 1 0-8.889 4.444 4.444 0 0 1 0 8.889zm8.89 12.444h21.333c.49 0 .889.398.889.89V24c0 .49-.398.889-.89.889H13.334a.889.889 0 0 1-.889-.889v-1.778c0-.49.398-.889.89-.889z"
        fill="#B446DA"
      />
    </svg>
  )
}
