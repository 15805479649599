import React, { Component } from 'react'

import './ProcessBox.scss'

export default class ProcessBox extends Component {
  render() {
    const { children } = this.props

    return <div className="process-box">{children}</div>
  }
}
