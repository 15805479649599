import React, { Component } from 'react'

import { Header, HomeTop, HomeTouch, HomeQuotes, ScrollToTopOnMount } from '../components'

export default class Home extends Component {
  render() {
    return (
      <>
        <ScrollToTopOnMount />
        <Header currentPage="home" />
        <HomeTop />
        <HomeTouch />
        <HomeQuotes />
      </>
    )
  }
}
