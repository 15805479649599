import React from 'react'

export default function Naming() {
  return (
    <svg width="44" height="36" viewBox="0 0 44 36">
      <path
        d="M40.1 29.61a1.69 1.69 0 0 0-.315 1.77 16.412 16.412 0 0 0 1.783 2.993.729.729 0 0 1-.627 1.138 12.984 12.984 0 0 1-5.34-1.935 1.704 1.704 0 0 0-1.434-.23 13.759 13.759 0 0 1-4.062.614c-7.248 0-13.123-5.529-13.123-12.349S22.857 9.263 30.105 9.263c7.247 0 13.123 5.528 13.123 12.348a11.876 11.876 0 0 1-3.128 8zm-24.662-7.999a13.2 13.2 0 0 0 1.4 5.918 1.24 1.24 0 0 1-1.155 1.792 17.56 17.56 0 0 1-5.327-.992 1.755 1.755 0 0 0-1.57.173 15.39 15.39 0 0 1-6.334 2.38c-.689.098-1.158-.802-.767-1.479a21.894 21.894 0 0 0 2.162-3.878 1.708 1.708 0 0 0-.312-1.743A13.729 13.729 0 0 1 0 14.665C0 6.566 7.258.001 16.21.001a16.837 16.837 0 0 1 12.763 5.626 1.35 1.35 0 0 1-.856 2.23c-7.226.915-12.679 6.778-12.679 13.754z"
        fill="#B446DA"
      />
    </svg>
  )
}
