import React, { Component } from 'react'

import { Header, ProcessColumns, ProcessTransparent, ProcessTop, ScrollToTopOnMount } from '../components'

export default class Process extends Component {
  render() {
    return (
      <>
        <ScrollToTopOnMount />
        <Header currentPage="process" />
        <ProcessTop />
        <ProcessColumns />
        <ProcessTransparent />
      </>
    )
  }
}
