import React, { Component } from 'react'

import { Twitter } from '../../svg'
import './HomePerson.scss'

export default class HomePerson extends Component {
  render() {
    const { name, children, image, handle } = this.props

    return (
      <div className="home-person">
        <div className="image">
          <img src={image} alt={'Photo of ' + name} />
        </div>
        <div className="text">
          <h2>{name}</h2>
          {children}
          <a href={'https://twitter.com/' + handle} target="_blank" rel="noopener noreferrer">
            <Twitter /> @{handle}
          </a>
        </div>
      </div>
    )
  }
}
