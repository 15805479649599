import React, { Component } from 'react'
import classNames from 'classnames'

import './HomeQuotes.scss'
import { HomeQuote } from '../'

export default class HomeQuotes extends Component {
  state = {
    active: 0,
    touched: false,
    quotes: [
      {
        quote:
          "Teddy's helped a number of YC companies and entities (like OpenAI), and comes personally recommended by Sam Altman (and his brother Jack), and Greg Brockman.",
        name: 'Amy Buechler',
        title: 'YCombinator',
        image: '/images/eric.jpg',
      },
      {
        quote:
          'Eric Friedman, who worked at USV and now works at Foursquare, was a very useful intermediary in a number of transactions',
        name: 'Fred Wilson',
        title:
          'Fred Wilson’s blog post on <a href="https://avc.com/2011/04/finding-and-buying-a-domain-name/">Finding And Buying a Domain</a>',
        image: '/images/eric.jpg',
      },
      {
        quote:
          "Teddy's a consummate professional: efficient, discreet and transparent. I've had multiple happy experiences working with Teddy and would rely on him for all my domain acquisition needs in the future.",
        name: 'Geoffrey Woo',
        title: 'CEO HVMN',
        image: '/images/eric.jpg',
      },
      {
        quote:
          "Eric & Teddy did a phenomenal job acquiring our domain name. As an engineer, I know just enough about the cutthroat domain name market to recognize that I shouldn't even think of trying to navigate it on my own. Fortunately, Eric and his team have the skill and experience needed to navigate these tricky waters. The process and costs were clearly outlined, they communicated openly and frequently to keep me updated on progress, and they were scrupulously honest and trustworthy throughout. They were able to acquire our first choice name very quickly, and even under budget! I would recommend Eric and his team to any founder looking to but a domain name. Trust me, you don't want to do this without their help.",
        name: 'Benjy Weinberger',
        title: 'CEO, Toolchain Labs',
        image: '/images/eric.jpg',
      },
    ],
  }

  componentDidMount() {
    window.cycle = setInterval(() => {
      const { active, quotes } = this.state

      if (active === quotes.length - 1) {
        this.setState({ active: 0 })
      } else {
        this.setState({ active: active + 1 })
      }
    }, 3500)
  }

  componentWillUnmount() {
    clearInterval(window.cycle)
  }

  setActive = active => {
    this.setState({ active, touched: true })
    clearInterval(window.cycle)
  }

  render() {
    const { quotes, active } = this.state

    return (
      <div className="home-quotes">
        <div className="width">
          <h2>Testimonials</h2>

          <HomeQuote {...quotes[active]} />

          <ul className="home-quotes-logos">
            <li onClick={() => this.setActive(0)} className={classNames({ active: active === 0 })}>
              <img className="y" src="/images/y.png" alt="Logo of Y Combinator" />
            </li>
            <li onClick={() => this.setActive(1)} className={classNames({ active: active === 1 })}>
              <img className="avc" src="/images/avc.png" alt="Logo of AVC" />
            </li>
            <li onClick={() => this.setActive(2)} className={classNames({ active: active === 2 })}>
              <img className="hvmn" src="/images/nvnm.png" alt="Logo of NVNM" />
            </li>
            <li onClick={() => this.setActive(3)} className={classNames({ active: active === 3 })}>
              <img className="toolchain" src="/images/toolchain.png" alt="Logo of Toolchain" />
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
