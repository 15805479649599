import React from 'react'

export default function Money() {
  return (
    <svg width="27" height="16" viewBox="0 0 27 16">
      <path
        d="M25.417 15.25h-24.4A1.017 1.017 0 0 1 0 14.233V1.017C0 .455.455 0 1.017 0h24.4c.561 0 1.016.455 1.016 1.017v13.216c0 .562-.455 1.017-1.016 1.017zM24.4 5.083a3.05 3.05 0 0 1-3.05-3.05H5.083a3.05 3.05 0 0 1-3.05 3.05v5.592a3.048 3.048 0 0 1 3.005 2.542h16.358a3.048 3.048 0 0 1 3.004-2.542V5.083zm-11.183 6.609c-1.859 0-3.05-1.589-3.05-4.067s1.191-4.067 3.05-4.067c1.858 0 3.05 1.589 3.05 4.067s-1.192 4.067-3.05 4.067z"
        fill="#b9c6d0"
      />
    </svg>
  )
}
