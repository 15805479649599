import React from 'react'

export default function Right({ color, width }) {
  return (
    <svg width={width || '22'} viewBox="0 0 22 17">
      <path
        d="M14.209 16.713a1 1 0 1 1-1.416-1.415l5.81-5.803H1a1 1 0 1 1 0-2h17.586l-5.793-5.787A1 1 0 1 1 14.209.294l7.511 7.502a1 1 0 0 1 0 1.414l-7.511 7.503z"
        fill={color || '#B446DA'}
      />
    </svg>
  )
}
