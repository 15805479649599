import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import './Button.scss'

export default function Button({ to, className, children }) {
  return (
    <Link to={to} className={classNames('button', className)}>
      {children}
    </Link>
  )
}
