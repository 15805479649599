import React, { Component } from 'react'

import './HomeTouch.scss'
import Calendly from '../Calendly/Calendly'
import { Button } from '../'
import { Stealth, Sellside, Naming, Right, UpRight } from '../../svg'

export default class HomeTouch extends Component {
  render() {
    return (
      <div className="home-touch">
        <div className="width">
          <div className="home-touch-box">
            <header className="home-touch-header">
              <h2>Get in touch</h2>
            </header>

            <div className="home-touch-body">
              <h2>We specialize in helping:</h2>
              <br />
                <ul className="home-touch-specialize">
                  <li>
                    Prelaunch startups deciding on a name
                  </li>
                  <li>
                    Established companies looking to rebrand
                  </li>
                  <li>
                    Large brands aiming to protect their IP or expand their portfolio
                  </li>
                </ul>

              <ul className="home-touch-list">
                <li>
                  <Stealth />
                  <h3>Domain Name Acquisition</h3>
                  <p>
                    We act as a private intermediary between your company and the seller, managing the outreach,
                    negotiation, and Escrow on your behalf.
                  </p>
                </li>
                <li>
                  <Naming />
                  <h3>Naming Consulting</h3>
                  <p>
                    We specialize in a process to brainstorm the right budget and name for clients.
                    Having a plan and options is key for any executive team, especially when
                    presenting a large purchase to a BOD.
                  </p>
                </li>
                <li>
                  <Sellside />
                  <h3>Brand Protection</h3>
                  <p>
                    We often help established brands protect their mark by acquiring geo-specific domains,
                    typos, and product name domains.
                  </p>
                </li>
              </ul>

              <footer className="home-touch-footer">
                <Calendly>
                  Schedule a meeting <UpRight />
                </Calendly>

                <Button to="/the-process" className="purple-secondary">
                  Learn about our process
                  <Right />
                </Button>
              </footer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
