import React, { Component } from 'react'

import './SaleDomains.scss'
import { SaleDomain } from '../'

export default class SaleDomains extends Component {
  render() {
    let { domains } = this.props

    return (
      <div className="sale-domains-section">
        <div className="width">
          <h2>All Premium Inventory</h2>

          <div className="sale-domains-list">
            <div className="sale-domain head" href="#">
              <span className="sale-domain-domain">Domain</span>
              <span className="sale-domain-description">Description</span>
              <span className="sale-domain-price">Price</span>
            </div>

            {domains && domains.map((domain, i) => <SaleDomain domain={domain} key={i} />)}
          </div>
        </div>
      </div>
    )
  }
}
