import React, { Component } from 'react'
import classNames from 'classnames'

import './ProcessBoxRow.scss'
import { ChatBubbles, Money, Paper } from '../../svg'

export default class ProcessBoxRow extends Component {
  getIcon = () => {
    const { icon, step } = this.props

    if (step !== undefined) {
      return <span className="step">{step}</span>
    } else if (icon === 'chat') {
      return <ChatBubbles />
    } else if (icon === 'paper') {
      return <Paper />
    } else if (icon === 'money') {
      return <Money />
    } else {
      return <span className="bullet" />
    }
  }

  render() {
    const { step, children } = this.props

    return (
      <div className={classNames('process-box-row', { main: step !== undefined })}>
        <span className="icon">{this.getIcon()}</span>
        <span className="text">{children}</span>
      </div>
    )
  }
}
