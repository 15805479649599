import React, { Component } from 'react'

import { airtable, airtable2 } from '../Airtable'
import { Header, SaleTop, SaleFeaturedList, SaleDomains, ScrollToTopOnMount } from '../components'
import InfiniteScroll from 'react-infinite-scroller';

export default class Sale extends Component {
  state = {
    featured: [],
    domains: [],
    next: () => {},
    hasMore: true
  }

  loadMore() {
    return this.state.next
  }

  componentDidMount() {
    const addRecords = (records, kind) => {
      this.setState({
        [kind]: [...this.state[kind], ...records],
      })
    }

    let featured = []

    airtable('Imported table')
      .select({
        maxRecords: 3000,
        view: 'Grid view',
      }).firstPage(
        function(err, records) {
          if (err) { console.error(err); return; }
          records.forEach(function(record) {
            featured.push(record.fields)
          })

          addRecords(featured, 'featured')
        }
      )

    let self = this;

    airtable2('Imported table')
      .select({
        view: 'Grid view',
      })
      .eachPage(
        function page(records, fetchNextPage) {
          let domains = []

          records.forEach(function(record) {
            domains.push(record.fields)
          })

          addRecords(domains, 'domains')
          domains = []

          self.setState({next: fetchNextPage})
          //fetchNextPage()
        },
        function done(err) {
          self.setState({hasMore: false})

          if (err) {
            return
          }
        }
      )
  }

  render() {
    return (
      <>
        <ScrollToTopOnMount />
        <Header />
        <SaleTop />
        <SaleFeaturedList domains={this.state.featured} />
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadMore()}
          hasMore={this.state.hasMore}
          loader={<div className="loader" key={0}>Loading</div>}
        >
          <SaleDomains domains={this.state.domains} />
        </InfiniteScroll>
      </>
    )
  }
}
