import React, { Component } from 'react'

import './SaleFeaturedList.scss'
import { SaleFeatured } from '../'

export default class SaleFeaturedList extends Component {
  render() {
    const { domains } = this.props

    return (
      <div className="sale-featured-section">
        <div className="width">
          <h2>Featured Inventory</h2>

          <div className="sale-featured-list">
            {domains && domains.map((domain, i) => <SaleFeatured domain={domain} key={i} />)}
          </div>
        </div>
      </div>
    )
  }
}
