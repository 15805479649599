import React, { Component } from 'react'

import { HomePerson } from '../'
import './HomeTop.scss'
import cookie from 'react-cookies'

export default class HomeTop extends Component {
  render() {
    let version = cookie.load('version') || 'full'

    return (
      <div className="home-top">
        <div className="width">
          <h1>
            We have been acquiring hard-to-get domains for founders and companies for over a decade.
          </h1>

          <div className="home-top-people">
            <HomePerson name="Teddy Worcester" handle="teddy" image="/images/teddy.jpg">
              <div className="person-location"><span role="img" aria-label="Location">🌎</span> Aspen, CO</div>
              <p>
                Teddy has over a decade of experience in the domain name industry as Head of R&D at Namecheap,
                the world's second largest retail registrar.
              </p>
            </HomePerson>
            { version === 'full' &&
              <HomePerson name="Eric Friedman" handle="ericfriedman" image="/images/eric.jpg">
                <div className="person-location"><span role="img" aria-label="Location">🌎</span> New York, NY</div>
                <p>
                  Eric has been investing and operating at early stage companies for the last decade.
                  He has worked with many venture scale companies, coaching them through the domain acquisition process.
                </p>
              </HomePerson>
            }
          </div>
        </div>
      </div>
    )
  }
}
