import React, { Component } from 'react'

import './Unlock.scss'

export default class Unlock extends Component {
  render() {
    const { entered, onChange } = this.props

    return (
      <div className="unlock">
        <div className="width">
          <h1>exclusive</h1>
          <input
            type="password"
            placeholder="••••••••••••••••"
            value={entered}
            onChange={e => onChange(e)}
          />
        </div>
      </div>
    )
  }
}
