import React, { Component } from 'react'

export default class ChatBubbles extends Component {
  render() {
    return (
      <svg width="29" height="23" viewBox="0 0 29 23">
        <path
          d="M26.16 19.163c-.284.313-.363.76-.205 1.151.317.688.705 1.34 1.16 1.946a.474.474 0 0 1-.408.74 8.443 8.443 0 0 1-3.472-1.258 1.108 1.108 0 0 0-.933-.15 8.947 8.947 0 0 1-2.64.4c-4.713 0-8.533-3.595-8.533-8.029 0-4.433 3.82-8.028 8.532-8.028 4.713 0 8.533 3.595 8.533 8.028a7.72 7.72 0 0 1-2.034 5.2zM10.16 14.015c0 1.332.315 2.646.921 3.837a.794.794 0 0 1-.044.795.82.82 0 0 1-.716.368 11.711 11.711 0 0 1-3.505-.643 1.17 1.17 0 0 0-1.034.112 10.222 10.222 0 0 1-4.169 1.544c-.454.062-.762-.521-.505-.96a14.14 14.14 0 0 0 1.423-2.515c.15-.385.072-.821-.205-1.13A8.83 8.83 0 0 1 0 9.51C0 4.258 4.777 0 10.67 0a11.158 11.158 0 0 1 8.4 3.65c.217.244.28.586.161.89a.886.886 0 0 1-.724.555c-4.757.593-8.346 4.396-8.346 8.92z"
          fill="#b9c6d0"
        />
      </svg>
    )
  }
}
