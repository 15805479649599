import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import onClickOutside from 'react-onclickoutside'

import './Header.scss'

export class Header extends Component {
  state = {
    burgerOpen: false,
  }

  openMenu = () => {
    this.setState({
      burgerOpen: !this.state.burgerOpen,
    })
  }

  handleClickOutside = () => {
    this.setState({
      burgerOpen: false,
    })
  }

  render() {
    const { burgerOpen } = this.state

    return (
      <div className={classNames('header', { active: burgerOpen })}>
        <div className="logo">
          <Link onClick={this.handleClickOutside} to="/">
            Exclusive
          </Link>
        </div>

        <button className={'hamburger'} onClick={this.openMenu}>
          <span className="bar" />
          <span className="bar" />
          <span className="bar" />
          <span className="bar" />
        </button>

        <ul className="nav">
          <li>
            <Link onClick={this.handleClickOutside} className={ this.props.currentPage === 'home' ? 'active' : ''} to="/">
              Services
            </Link>
          </li>
          <li>
            <Link onClick={this.handleClickOutside} className={ this.props.currentPage === 'process' ? 'active' : ''} to="/the-process">
              The Process
            </Link>
          </li>
          <li className="header-contact-us">
            <a href="mailto:tw@exclusive.io" target="_blank" rel="noreferrer noopener">
              Contact Us
            </a>
          </li>
          <li className="header-for-sale">
            <Link onClick={this.handleClickOutside} to="/for-sale">
              Domains For Sale
            </Link>
          </li>
        </ul>
      </div>
    )
  }
}

export default onClickOutside(Header)
